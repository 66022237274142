import { Box, CircularProgress, RadioGroup, Typography, circularProgressClasses } from '@mui/material';
import { useTheme } from "@mui/material/styles";
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CORE_ACTION_POST_SIGN_IN_INVITATION_VALIDATION, CORE_ACTION_POST_SIGN_IN_VALIDATION, CORE_ACTION_POST_SIGN_UP_EMAIL_VALIDATION } from '../Actions/CoreActions';
import { AppContext } from '../App';
import CircleCheck from '../assets/icons/CircleCheck';
import CircleX from '../assets/icons/CircleX';
import CustomDialog from '../Components/DialogComponent/DialogContainer/CustomDialog';
import CustomDialogContent from '../Components/DialogComponent/DialogContainer/CustomDialogContent';
import DivFlexCenter from '../Components/DivFlexCenter';
import DivFlexSpaceBetween from '../Components/DivFlexSpacebetween';
import DivFlexStart from '../Components/DivFlexStart';
import { ButtonSizeEnum } from '../Components/NewButton/ButtonSizeEnum';
import { ButtonVariantEnum } from '../Components/NewButton/ButtonVariantEnum';
import NewButton from '../Components/NewButton/NewButton';
import Radio from '../Components/Radio';
import { FontFamily } from '../Constants/FontFamily';
import { VerificationStatus } from '../Constants/VerificationStatus';

const CustomerList = (props) => {
    const { isDesktop, setOutletId, setLoggedinEmail, persistedLinkString } = useContext(AppContext)

    const { t } = useTranslation()

    const navigate = useNavigate()

    const [selectedCustomer, setSelectedCustomer] = useState('')

    const theme = useTheme()

    const { data } = props

    const handleRadio = e => {
        setSelectedCustomer(e.target.value)
    }

    const handleSelectBox = customerNumber => () => {
        setSelectedCustomer(customerNumber)
    }

    const handleSubmit = () => {
        const selectedData = data.filter(d => d.customerNumber === selectedCustomer)[0]

        setOutletId(selectedData.outlets[0].id)
        setLoggedinEmail(selectedData.email)

        navigate(`/${persistedLinkString}`, { replace: true })
    }

    return (
        <>
            {data && (
                <>
                    <DivFlexCenter
                        sx={{
                            textAlign: 'center'
                        }}
                    >
                        <Typography
                            sx={{
                                color: theme.palette.text.primary,
                                fontSize: isDesktop ? 44 : 24,
                                fontFamily: isDesktop ? FontFamily.PLUSJAKARTASANSBOLD : FontFamily.PLUSJAKARTASANSSEMIBOLD,
                                lineHeight: isDesktop ? '57px' : 'normal'
                            }}
                        >
                            {t("welcomeToCustomerPortal")}
                        </Typography>
                    </DivFlexCenter>

                    <DivFlexCenter
                        sx={{
                            mt: isDesktop ? '64px' : '22px',
                            textAlign: 'center'
                        }}
                    >
                        <Typography
                            sx={{
                                color: theme.palette.text.primary,
                                fontSize: isDesktop ? 20 : 14,
                                fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD,
                                lineHeight: isDesktop ? '28px' : '22px'
                            }}
                        >
                            {data[0]?.email ? `${t("hallo")}, ${data[0]?.email}` : `${t("hallo")}`}
                        </Typography>
                    </DivFlexCenter>

                    <Box
                        sx={{
                            mt: isDesktop ? '24px' : '16px',
                            textAlign: 'center'
                        }}
                    >
                        <Typography
                            sx={{
                                color: theme.palette.text.primary,
                                fontSize: isDesktop ? 20 : 14,
                                fontFamily: FontFamily.PLUSJAKARTASANS,
                                fontWeight: 400
                            }}
                        >
                            {t('selectCustomerData')}
                        </Typography>
                    </Box>

                    <Box sx={{ mt: isDesktop ? '60px' : '16px' }} />

                    <Box
                        sx={{
                            // border: '1px solid red',
                            // width: !isDesktop && '-webkit-fill-available',
                            height: isDesktop ? '400px' : '300px',
                            overflow: 'auto',
                            position: 'relative'
                        }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                backgroundColor: theme.palette.variables.cardHover,
                                padding: isDesktop ? '12px 16px' : '8px 16px',
                                boxSizing: 'border-box',
                                display: 'flex',
                                justifyContent: 'space-between',
                                position: 'sticky',
                                top: 0,
                                left: 0,
                                zIndex: 100
                            }}
                        >
                            <Typography
                                sx={{
                                    color: theme.palette.text.primary,
                                    fontSize: isDesktop ? 18 : 12,
                                    fontFamily: FontFamily.PLUSJAKARTASANSBOLD,
                                    fontWeight: 400,
                                    lineHeight: isDesktop ? '25px' : '17px'
                                }}
                            >
                                {t("customerNumber")}
                            </Typography>
                            <Typography
                                sx={{
                                    color: theme.palette.text.primary,
                                    fontSize: isDesktop ? 18 : 12,
                                    fontFamily: FontFamily.PLUSJAKARTASANSBOLD,
                                    fontWeight: 400,
                                    lineHeight: isDesktop ? '25px' : '17px'
                                }}
                            >
                                {data.length}
                            </Typography>
                        </Box>

                        <RadioGroup
                            name={"customerSelectionSignIn"}
                            value={selectedCustomer}
                            onChange={handleRadio}
                        >
                            {data.map(customer => (
                                <Box
                                    sx={{
                                        width: '100%',
                                        padding: isDesktop ? '12px 16px' : '8px 16px',
                                        borderBottom: '2px solid #D1D2D1',
                                        boxSizing: 'border-box',
                                        display: 'flex',
                                        alignItems: 'center',
                                        cursor: 'pointer'
                                    }}
                                    onClick={handleSelectBox(customer.customerNumber)}
                                >
                                    <Box
                                        sx={{
                                            // width: '100px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'flex-start'
                                        }}
                                    >
                                        <Radio
                                            value={customer.customerNumber}
                                            label={customer.customerNumber}
                                            checked={selectedCustomer === customer.customerNumber}
                                        />
                                    </Box>
                                    {customer.outlets.map((outlet, index) => (
                                        <DivFlexStart>
                                            {index > 0 && (
                                                <Typography
                                                    sx={{
                                                        color: theme.palette.text.primary,
                                                        fontSize: isDesktop ? 12 : 10,
                                                        fontFamily: FontFamily.PLUSJAKARTASANSBOLD,
                                                        fontWeight: 400,
                                                        lineHeight: isDesktop ? '25px' : '17px',
                                                        marginLeft: `4px`,
                                                        marginRight: `4px`,
                                                        textOverflow: 'ellipsis'
                                                    }}
                                                >
                                                    {`&`}
                                                </Typography>
                                            )}
                                            <Typography
                                                sx={{
                                                    color: theme.palette.text.primary,
                                                    fontSize: isDesktop ? 12 : 10,
                                                    fontFamily: FontFamily.PLUSJAKARTASANSBOLD,
                                                    fontWeight: 400,
                                                    lineHeight: isDesktop ? '25px' : '17px'
                                                }}
                                            >
                                                {`${outlet.street}, ${outlet.city} ${outlet.zipcode}`}
                                            </Typography>
                                        </DivFlexStart>

                                    ))}
                                </Box>
                            ))}
                        </RadioGroup>

                    </Box>

                    {isDesktop ? (
                        <Box
                            sx={{
                                width: '100%',
                                // border: '1px solid red',
                            }}
                        >
                            <DivFlexSpaceBetween>

                                <NewButton
                                    size={ButtonSizeEnum.LARGE}
                                    variant={ButtonVariantEnum.TERTIARY}
                                // onClick={handleCloseDialog}
                                >
                                    {``}
                                </NewButton>

                                <Box
                                    sx={{ display: 'flex', gap: '16px' }}
                                >
                                    <NewButton
                                        size={ButtonSizeEnum.LARGE}
                                        variant={ButtonVariantEnum.PRIMARY}
                                        isDisabled={selectedCustomer === ''}
                                        onClick={handleSubmit}
                                    >
                                        {t('continue')}
                                    </NewButton>
                                </Box>
                            </DivFlexSpaceBetween>
                        </Box>
                    ) : (
                        <DivFlexCenter
                            sx={{
                                flexDirection: 'column',
                                gap: '8px',
                                width: '100%'
                            }}
                        >
                            <NewButton
                                variant={ButtonVariantEnum.PRIMARY}
                                size={ButtonSizeEnum.SMALL}
                                isFullWidth
                                isDisabled={selectedCustomer === ''}
                                onClick={handleSubmit}
                            >
                                {t('continue')}
                            </NewButton>
                        </DivFlexCenter>
                    )}
                </>
            )}
        </>
    )
}

const Verify = () => {
    const { t } = useTranslation()
    const theme = useTheme()
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const [verificationStatus, setVerificationStatus] = useState(null)

    const { isDesktop, isMobile, setLoggedinEmail, setOutletId, setPersistedLinkString } = useContext(AppContext)

    const [signInDataSelection, setSignInDataSelection] = useState(null)

    useEffect(() => {
        handleVerifyEmailAction()
    }, [])

    const handleVerifyEmailAction = () => {
        setVerificationStatus(null)

        let link = searchParams.get("link")
        let type = searchParams.get("type")

        if (type === 'signin') {
            if (link != null && link != "") {
                const decodedPayloads = atob(link)
                const payloads = decodedPayloads.split(";")

                const email = payloads[0]
                const otp = payloads[1]

                CORE_ACTION_POST_SIGN_IN_VALIDATION(
                    email,
                    otp,
                    res => {
                        // console.log(res)
                        if (res.status === 200 && res.data) {
                            if (res.data.outlets.length > 0) {
                                const newSignInData = []
                                const customerNumbers = []
                                res.data.outlets.forEach(outlet => {
                                    if (!customerNumbers.includes(outlet.customer_number)) {
                                        if (outlet.customer_number) {
                                            customerNumbers.push(outlet.customer_number)
                                        }
                                    }
                                })

                                customerNumbers.forEach(customerNumber => {
                                    newSignInData.push({
                                        email: email,
                                        customerNumber: customerNumber,
                                        outlets: []
                                    })
                                })

                                let foundCustomer = null

                                res.data.outlets.forEach(outlet => {
                                    foundCustomer = newSignInData.filter(data => data.customerNumber === outlet.customer_number)[0]

                                    if (foundCustomer) {
                                        foundCustomer.outlets.push(outlet)
                                    }
                                })

                                setSignInDataSelection(newSignInData)

                                if (newSignInData.length > 0) {
                                    setVerificationStatus("PENDING_SELECT_DATA")
                                }
                            }
                        }
                    },
                    err => {
                        console.log(err)
                    }
                )
            }
        }
        else if (type === 'signin_inv') {
            if (link != null && link != "") {
                const decodedPayloads = atob(link)
                const payloads = decodedPayloads.split(";")

                const email = payloads[0]
                const outletId = payloads[1]
                const otp = payloads[2]

                CORE_ACTION_POST_SIGN_IN_INVITATION_VALIDATION(
                    email,
                    outletId,
                    otp,
                    res => {
                        // console.log(res)
                        if (res.status === 200 && res.data) {
                            if (res.data.outlets.length > 0) {
                                const newSignInData = []
                                const customerNumbers = []
                                res.data.outlets.forEach(outlet => {
                                    if (!customerNumbers.includes(outlet.customer_number)) {
                                        if (outlet.customer_number) {
                                            customerNumbers.push(outlet.customer_number)
                                        }
                                    }
                                })

                                customerNumbers.forEach(customerNumber => {
                                    newSignInData.push({
                                        email: email,
                                        customerNumber: customerNumber,
                                        outlets: []
                                    })
                                })

                                let foundCustomer = null

                                res.data.outlets.forEach(outlet => {
                                    foundCustomer = newSignInData.filter(data => data.customerNumber === outlet.customer_number)[0]

                                    if (foundCustomer) {
                                        foundCustomer.outlets.push(outlet)
                                    }
                                })

                                setSignInDataSelection(newSignInData)

                                if (newSignInData.length > 0) {
                                    setVerificationStatus("PENDING_SELECT_DATA")
                                }
                            }
                        }
                    },
                    err => {
                        console.log(err)
                    }
                )
            }
        }
        else {
            localStorage.removeItem("welcomeGreeting");

            if (link != null && link != "") {
                const decodedPayloads = atob(link)
                const payloads = decodedPayloads.split(";")

                const registrationKey = payloads[0]
                const outletId = payloads[1]
                const email = payloads[2]
                const hashedOtp = payloads[3]

                localStorage.removeItem("welcomeGreeting");

                CORE_ACTION_POST_SIGN_UP_EMAIL_VALIDATION(
                    registrationKey,
                    outletId,
                    hashedOtp,
                    email,
                    res => {
                        // console.log(res)
                        if (res.status === 200 && res.data.message === `OK`) {
                            if (res.data.responseData) {

                                let messageData = res.data.responseData.message;
                                let etaLink = res.data.responseData.etaLink;

                                if (messageData === VerificationStatus.ALREADYVERIFIED) {
                                    setVerificationStatus(VerificationStatus.ALREADYVERIFIED)

                                    localStorage.removeItem("welcomeGreeting");
                                    setLoggedinEmail(email)
                                    setOutletId(outletId)
                                    setPersistedLinkString(etaLink)

                                    setTimeout(() => {
                                        localStorage.removeItem("welcomeGreeting");
                                        handleGoToMainPage(etaLink)
                                    }, 5000);
                                }
                                else if (messageData === 'EMAIL_VERIFICATION_SUCCESSFUL') {
                                    setVerificationStatus(VerificationStatus.SUCCESS)

                                    localStorage.removeItem("welcomeGreeting");
                                    setLoggedinEmail(email)
                                    setOutletId(outletId)
                                    setPersistedLinkString(etaLink)

                                    setTimeout(() => {
                                        localStorage.removeItem("welcomeGreeting");
                                        handleGoToMainPage(etaLink)
                                    }, 5000);
                                }
                                else if (messageData === 'OTP_EXPIRED') {
                                    setVerificationStatus("OTP_EXPIRED")
                                }
                            }
                        }
                    },
                    err => {
                        console.log(err)
                        if (err) {
                            console.error(err)
                            setVerificationStatus("ERROR")
                        }
                    }
                )
            }
        }
    }

    const handleGoToMainPage = (link) => {
        navigate(`/${link}`, { replace: true })
    }

    return (
        <Box sx={{ height: '100vh', overflow: 'auto' }}>
            {/* <TopNavbar
                shouldShowSettings={false}
                theme={theme}
            /> */}
            <DivFlexStart sx={{ width: '100%', height: '100%', flexWrap: 'wrap', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Box
                    sx={{
                        width: 800,
                        textAlign: 'center',
                        padding: !isDesktop && '0px 24px'
                    }}
                >
                    {verificationStatus ? (
                        <>
                            {verificationStatus === VerificationStatus.PENDINGSELECTDATA  ? (
                                <CustomDialog
                                    open={true}
                                    onClose={undefined}
                                    theme={theme}
                                    maxWidth={'md'}
                                >
                                    <Box sx={{ backgroundColor: theme.palette.background.dialog }}>
                                        <CustomDialogContent
                                            isMobile={isMobile}
                                            sx={{
                                                backgroundColor: theme.palette.background.dialog,
                                                padding: isMobile ? '0px 16px 24px 16px' : '0px 64px 64px 64px'
                                            }}
                                            theme={theme}
                                        >
                                            <CustomerList
                                                data={signInDataSelection}
                                            />
                                        </CustomDialogContent>
                                    </Box>
                                </CustomDialog>
                            ) : (
                                <>
                                    {(verificationStatus === VerificationStatus.ALREADYVERIFIED || verificationStatus === VerificationStatus.SUCCESS) ? (
                                        <CircleCheck
                                            style={{
                                                width: isDesktop ? 160 : 66,
                                                height: isDesktop ? 160 : 66,
                                                color: theme.palette.background.iconColor
                                            }}
                                        />
                                    ) : (
                                        <CircleX
                                            style={{
                                                width: isDesktop ? 160 : 66,
                                                height: isDesktop ? 160 : 66,
                                                color: theme.palette.background.iconColor
                                            }}
                                        />
                                    )}

                                    {(verificationStatus === VerificationStatus.ALREADYVERIFIED || verificationStatus === VerificationStatus.SUCCESS) ? (
                                        <Typography
                                            fontSize={isDesktop ? 48 : 22}
                                            color={theme.palette.text.primary}
                                            sx={{ fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD, lineHeight: '40px', marginTop: isDesktop ? '64px' : '40px' }}
                                        >
                                            {verificationStatus === VerificationStatus.ALREADYVERIFIED ? t('emailAlreadyVerified') : t('verificationSuccessful')}
                                        </Typography>
                                    ) : (
                                        <Typography
                                            fontSize={isDesktop ? 48 : 22}
                                            color={theme.palette.text.primary}
                                            sx={{ fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD, lineHeight: '40px', marginTop: isDesktop ? '64px' : '40px' }}
                                        >
                                            {t('verificationFailed')}
                                        </Typography>
                                    )}

                                    {(verificationStatus === VerificationStatus.ALREADYVERIFIED || verificationStatus === VerificationStatus.SUCCESS) ? (
                                        <Typography
                                            fontSize={isDesktop ? 20 : 14}
                                            color={theme.palette.text.primary}
                                            sx={{ fontFamily: FontFamily.PLUSJAKARTASANS, fontWeight: 400, marginTop: isDesktop ? '32px' : '20px' }}
                                        >
                                            {verificationStatus === VerificationStatus.ALREADYVERIFIED ? t('verificationDesc1') : t('verificationDesc2')}
                                        </Typography>
                                    ) : (
                                        <Typography
                                            fontSize={isDesktop ? 20 : 14}
                                            color={theme.palette.text.primary}
                                            sx={{ fontFamily: FontFamily.PLUSJAKARTASANS, fontWeight: 400, marginTop: isDesktop ? '32px' : '20px' }}
                                        >
                                            {verificationStatus === VerificationStatus.OTPEXPIRED ? (
                                                <>
                                                    {t('verificationDesc4')}
                                                </>
                                            ) : (
                                                <>
                                                    {t('verificationDesc3')}
                                                </>
                                            )}

                                        </Typography>
                                    )}

                                    {verificationStatus === 'ERROR' && (
                                        <Typography
                                            sx={{
                                                fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD,
                                                fontSize: isDesktop ? 21 : 14,
                                                color: theme.palette.text.primary,
                                                textDecorationLine: 'underline',
                                                cursor: 'pointer',
                                                marginTop: isDesktop ? '32px' : '20px'
                                            }}
                                            onClick={handleVerifyEmailAction}
                                        >
                                            {t('tryAgain')}
                                        </Typography>
                                    )}
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            <CircularProgress
                                sx={{
                                    color: theme.palette.background.iconColor,
                                    // animationDuration: "0ms",
                                    [`& .${circularProgressClasses.circle}`]: {
                                        strokeLinecap: "round",
                                    }
                                }}
                                thickness={4.6}
                                disableShrink
                                size={isDesktop ? '100px' : '50px'}
                            />

                            <Typography
                                fontSize={isDesktop ? 48 : 22}
                                color={theme.palette.text.primary}
                                sx={{ fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD, lineHeight: '40px', marginTop: isDesktop ? '64px' : '40px' }}
                            >
                                {t('1_1-1-14_loader_text')}
                            </Typography>
                        </>
                    )}
                </Box>
            </DivFlexStart>
        </Box>

    )
}

export default Verify;