import { Box } from "@mui/material"
import { createContext, useContext, useEffect, useState } from "react"
import { AppContext } from "../../App"
import loginBackground from "../../assets/images/loginBackground.png"

import { CORE_ACTION_POST_SIGN_IN } from "../../Actions/CoreActions"
import { SignUpPages } from "../../Constants/SignUpPages"
import { TimerResendActivation } from "../../Constants/TimerResendActivation"
import SignInDesktop from "./SignInDesktop"
import SignInMobile from "./SignInMobile"

export const SignInContext = createContext({})

const SignIn = () => {
    const {
        isLoadingLogin,
        isDesktop,
        currentPage,
        setCurrentPage,
        expiredTimer,
        resendTimer,
        setResendTimer,
        setExpiredTimer,
        signUpImage,
    } = useContext(AppContext)

    // Email
    const [email, setEmail] = useState("")
    const [isEmailSubmit, setIsEmailSubmit] = useState(false)
    const [isEmailEmpty, setIsEmailEmpty] = useState(false)
    const [isEmailInvalid, setIsEmailInvalid] = useState(false)
    const [isEmailExist, setIsEmailExist] = useState(false)
    const [isError, setIsError] = useState(false)

    const [signInLoading, setSignInLoading] = useState(false)

    const [resendActivationEmailLoading, setResendActivationEmailLoading] = useState(false)

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (resendTimer > 0) {
                setResendTimer((prevResendTimer) => prevResendTimer - 1)
            }
            else {
                clearInterval(intervalId)
            }
        }, 1000)

        return () => clearInterval(intervalId)
    }, [resendTimer])

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (expiredTimer > 0) {
                setExpiredTimer((prevExpiredTimer) => prevExpiredTimer - 1)
            }
            else {
                clearInterval(intervalId)
            }
        }, 1000)

        return () => clearInterval(intervalId)
    }, [expiredTimer])

    const isValidEmail = (value) => {
        return /\S+@\S+\.\S+/.test(value);
    }

    const handleChangeEmail = e => {
        setEmail(e.target.value)
    }

    const handleSubmitEmail = () => {
        if (email !== "") {
            setSignInLoading(true)
            setIsEmailInvalid(false)
            setIsEmailEmpty(false)
            setIsError(false)
            if (isValidEmail(email)) {
                CORE_ACTION_POST_SIGN_IN(
                    email,
                    res => {
                        if (res.status === 200 && res.data) {
                            if (res.data.message !== null && res.data.message === 'OK' && res.data.email === email) {
                                setCurrentPage(SignUpPages.EMAIL_CONFIRMATION)
                                setResendTimer(TimerResendActivation.RESEND_TIMER)
                                setExpiredTimer(TimerResendActivation.EXPIRED_TIMER)
                            }
                            else if (res.data.message !== null && res.data.message === 'BAD_REQUEST') {
                                setIsError(true);
                            }
                            else if (res.data.message != null && res.data.message === 'EMAIL_NOT_FOUND') {
                                setIsEmailInvalid(true);
                            }
                            
                            setSignInLoading(false)
                            setResendActivationEmailLoading(false)
                        }
                    },
                    err => {
                        setIsEmailInvalid(true);
                        setIsError(true);

                        setSignInLoading(false)
                        setResendActivationEmailLoading(false)
                    }
                )
            } else {
                setIsEmailInvalid(true);

                setSignInLoading(false)
                setResendActivationEmailLoading(false)
            }
        }

        if (email === "") {
            setIsEmailEmpty(true);
            setSignInLoading(false)
        }
    }

    const resendActivation = () => {
        if (resendTimer <= 0) {
            setResendActivationEmailLoading(true)
            handleSubmitEmail()
        }
    }

    const handleUseAnotherEmail = () => {
        setCurrentPage(SignUpPages.INPUT_REG_EMAIL)
    }

    const SignInContextValue = {
        currentPage,
        setCurrentPage,

        email,
        setEmail,
        isEmailSubmit,
        isEmailEmpty,
        isEmailInvalid,
        isEmailExist,
        setIsEmailExist,
        handleChangeEmail,
        handleSubmitEmail,

        signInLoading,

        resendTimer,
        expiredTimer,
        resendActivation,
        resendActivationEmailLoading,

        handleUseAnotherEmail
    }

    return (
        <SignInContext.Provider value={SignInContextValue}>
            <Box container sx={{ display: 'flex', flexDirection: isDesktop ? 'row' : 'column' }}>
                {!isLoadingLogin && (
                    isDesktop ?
                        <SignInDesktop img={signUpImage ? signUpImage : loginBackground} />
                        :
                        <SignInMobile img={signUpImage ? signUpImage : loginBackground} />
                )}
            </Box>
        </SignInContext.Provider>
    )
}

export default SignIn