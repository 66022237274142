import React, { useContext, useEffect, useState } from "react";

// import i18n
import { useTranslation } from "react-i18next";

// import context
import { AppContext } from "../../App";
import { SignInContext } from "./SignIn";

// import theme
import { useTheme } from "@mui/material/styles";

// import constants
import { BannerVariant } from "../../Constants/BannerVariant";
import { FontFamily } from "../../Constants/FontFamily";
import { SignUpPages } from "../../Constants/SignUpPages";

// import icons

// import components
import { Box, Paper, Skeleton, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { CORE_ACTION_GET_OUTLET_EMAILS_FROM_ETA_LINK } from "../../Actions/CoreActions";
import Banner from "../../Components/Banner/Banner";
import Button from "../../Components/Button";
import DropdownSelect from "../../Components/DropdownSelect";
import ErrorMessage from "../../Components/ErrorMessage";
import TextFieldStyled from "../../Components/TextField/TextFieldStyle";
import TopNavbar from "../../Components/TopNavbar/TopNavbar";

const CompanyLogo = (props) => {
    const { theme } = props
    const { logo, isLogoImage } = useContext(AppContext)

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                px: 8
            }}
        >
            {isLogoImage ? (
                <img
                    src={logo}
                    alt="company logo"
                    style={{
                        width: '100%',
                        maxWidth: '250px'
                    }}
                />
            ) : (
                <Typography
                    color={theme.palette.text.topNavbarText}
                    sx={{
                        fontFamily: FontFamily.PLUSJAKARTASANSBOLD,
                        fontSize: "18px",
                        lineHeight: "18px",
                        textTransform: 'uppercase',
                    }}
                >
                    {logo}
                </Typography>
            )}
        </Box>
    )
}

const EmailConfirmationFlow = props => {
    const {
        handleUseAnotherEmail,
        resendTimer,
        expiredTimer,
        resendActivation
    } = useContext(SignInContext)

    const { t } = useTranslation()

    const { theme, responsiveFontSize } = props

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    gap: 3,
                    py: 5,
                }}
            >
                <Typography sx={{ fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD, fontSize: responsiveFontSize.heading, color: theme.palette.text.primary, textAlign: 'center' }}>
                    {t("oneMoreStepAndAllSet")}
                </Typography>

                {expiredTimer <= 0 &&
                    <Banner
                        variant={BannerVariant.DANGER}
                        fontSize={responsiveFontSize.banner}
                        width={'100%'}
                    >
                        {t("theActivationLinkIsExpired")}
                    </Banner>
                }

                <Typography sx={{ fontFamily: FontFamily.PLUSJAKARTASANS, fontSize: responsiveFontSize.body, color: theme.palette.text.primary, textAlign: 'center' }}>
                    {t('2_1-2-3_bridging_subtitle')}
                    <br /><br />
                    {t('3_1-2-3_bridging_subtitle')}
                </Typography>
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    textAlign: 'center',
                    gap: 10
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography
                        sx={{
                            fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD,
                            fontSize: responsiveFontSize.body,
                            textDecorationLine: 'underline',
                            cursor: resendTimer <= 0 && 'pointer',
                            color: resendTimer > 0 ? theme.palette.text.disabled : theme.palette.text.primary
                        }}
                        onClick={resendActivation}
                    >
                        {`${t("resendActivationEmail")} ${resendTimer > 0 ? `(${resendTimer})` : ``}`}
                    </Typography>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography
                        sx={{
                            fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD,
                            fontSize: responsiveFontSize.body,
                            textDecorationLine: 'underline',
                            cursor: 'pointer',
                            color: theme.palette.text.primary
                        }}
                        onClick={handleUseAnotherEmail}
                    >
                        {t("useAnotherEmailAddress")}
                    </Typography>
                </Box>
            </Box>
        </>
    )
}

const InputEmailManualFlow = props => {
    const {
        email,
        setEmail,
        isEmailEmpty,
        isEmailInvalid,
        isEmailExist,
        handleChangeEmail,
        handleSubmitEmail,
        signInLoading
    } = useContext(SignInContext)

    const {
        setShouldShowSettings,
        setIsLoading,
        isLoading
    } = useContext(AppContext)

    const { t } = useTranslation()

    const { theme, responsiveFontSize } = props

    const [emails, setEmails] = useState([])

    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {
        setShouldShowSettings(false)
        setIsLoading(true)
        let link = searchParams.get("link")

        if (!link) {
            link = ""
        }

        CORE_ACTION_GET_OUTLET_EMAILS_FROM_ETA_LINK(
            link,
            res => {
                if (res.data) {
                    const emailOptions = []
                    res.data.forEach(email => {
                        emailOptions.push({
                            value: email.email,
                            label: email.email
                        })
                    })
                    setEmail(emailOptions[0].value)
                    setEmails(emailOptions)
                }
                setIsLoading(false)
            },
            err => {
                setIsLoading(false)
                console.log(err)
            })

    }, [])

    const isFieldInvalid = isEmailEmpty || isEmailInvalid || isEmailExist

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    gap: 3,
                    py: 5,
                    textAlign: 'center'
                }}
            >
                <Typography sx={{ fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD, fontSize: responsiveFontSize.heading, color: theme.palette.text.primary }}>
                    {t("1_1-2-6_header_title")}
                </Typography>
                <Typography sx={{ fontFamily: FontFamily.PLUSJAKARTASANS, fontSize: responsiveFontSize.body, color: theme.palette.text.primary }}>
                    {t("2_1-2-6_header_subtitle")}
                </Typography>
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1
                }}
            >
                <Typography sx={{ fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD, fontSize: responsiveFontSize.body, color: theme.palette.text.primary }}>
                    {`${t("email")} *`}
                </Typography>

                {isLoading ? (
                    <Skeleton variant="rounded" width={'100%'} height={49} />
                ) : (
                    <>
                        {emails.length > 0 ? (
                            <DropdownSelect
                                options={emails}
                                value={email}
                                handleChangeSelect={handleChangeEmail}
                            />
                        ) : (
                            <TextFieldStyled
                                placeholder="example@mail.com"
                                style={{ width: '100%' }}
                                padding="16px"
                                value={email}
                                onChange={handleChangeEmail}
                                warning={isFieldInvalid}
                            />

                        )}
                        {isFieldInvalid &&
                            <ErrorMessage
                                isEmailEmpty={isEmailEmpty}
                                isEmailInvalid={isEmailInvalid}
                                isEmailExist={isEmailExist}
                                isDesktop={false}
                                isMobile={true}
                            />
                        }
                    </>
                )}
            </Box>

            <Box
                sx={{
                    pt: 12.5
                }}
            >
                <Button
                    style={{
                        width: '100%',
                        paddingY: '16px',
                        height: ''
                    }}
                    size="medium"
                    isLoading={signInLoading}
                    onClick={handleSubmitEmail}
                >
                    {t("submit")}
                </Button>
            </Box>
        </>
    )
}

const SignInMobile = (props) => {
    const { img } = props
    const { currentPage, isLogin } = useContext(SignInContext)
    const { isMobile } = useContext(AppContext)

    const theme = useTheme()

    const fontSize = {
        heading: isMobile ? 22 : 28,
        body: isMobile ? 14 : 20,
        banner: isMobile ? 12 : 18
    }

    const selectPageContent = () => {
        switch (currentPage) {
            case SignUpPages.INPUT_REG_EMAIL:
                return <InputEmailManualFlow theme={theme} responsiveFontSize={fontSize} />
            case SignUpPages.EMAIL_CONFIRMATION:
                return <EmailConfirmationFlow theme={theme} responsiveFontSize={fontSize} />
            default:
                return <InputEmailManualFlow theme={theme} responsiveFontSize={fontSize} />
        }
    }

    return (
        <React.Fragment>
            <Box sx={{ position: 'fixed', height: '100vh', width: '100vw' }}>
                <img src={img} alt="company logo" style={{ objectFit: 'cover', objectPosition: 'center', height: '100vh', width: '100vw' }} />
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', minHeight: '100vh', position: 'absolute', zIndex: 1 }}>
                <Box sx={{ background: 'linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0.00) 93.75%)', height: '360px', width: '100vw', position: 'absolute' }} />
                <Box sx={{ width: '100vw' }}>
                    <TopNavbar
                        shouldShowSettings={false}
                        theme={theme}
                        isLogin={isLogin}
                        isSignUp
                    />
                </Box>
                <Box
                    sx={{
                        width: '100vw',
                        height: '100%',
                        minHeight: '100vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'absolute',
                    }}
                >
                    <Paper
                        elevation={0}
                        square
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            backgroundColor: theme.palette.background.signUpDialog,
                            width: `calc(100vw - 48px)`,
                            px: 2,
                            py: 5,
                            [theme.breakpoints.up('sm')]: {
                                px: 8,
                                py: 10
                            },
                            [theme.breakpoints.up('md')]: {
                                px: 12,
                                py: 15
                            }
                        }}
                    >
                        <CompanyLogo theme={theme} />
                        {selectPageContent()}
                    </Paper>
                </Box>
            </Box>
        </React.Fragment>
    )
}

export default SignInMobile