export const UrlPage = {
    VERIFY: '/verify',
    DELIVERY_NO_LINK: '/',
    DELIVERY: '/:link',
    TRACKINGPAGE: '/tracking-page',
    SIGN_UP: '/start',
    SIGN_IN: '/login',
    CONFIGURATION: '/configuration',
    REDIRECT: '/redirect',
    DEMO: '/static/aSk9EQSvRD6rjyK3zWjmp1e'
}