import { useTheme } from "@emotion/react";
import { FormControl, MenuItem, Select, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../App";
import { FontFamily } from "../Constants/FontFamily";
import ChevronDown from "../assets/icons/ChevronDown";

const StyledSelect = styled(Select)(({ isMenulistOpen, isDesktop, theme }) => ({
    borderRadius: 0,
    border: 'none',
    boxShadow: isMenulistOpen ? `0 0 0 1px ${theme.palette.variables.borderActive} inset` : `0 0 0 2px ${theme.palette.variables.borderDefault} inset`,
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none'
    },
    '& .MuiOutlinedInput-input': {
        padding: isDesktop ? '24px 16px' : '16px',
        minHeight: '0px !important',
        '& .MuiTypography-root': {
            fontFamily: FontFamily.PLUSJAKARTASANS,
            fontSize: isDesktop ? '20px' : '14px',
            fontWeight: 400,
            fontStyle: 'normal',
            lineHeight: isDesktop ? '28px' : '17px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            color: isMenulistOpen ? theme.palette.variables.prominent : theme.palette.variables.mutedDefault
        }
    },
    '& .MuiSelect-select': {
        '&.MuiInputBase-input': {
            '&.MuiOutlinedInput-input': {
                paddingRight: '48px'
            }
        }
    },
    '& .MuiSelect-icon': {
        fontSize: '24px',
        color: theme.palette.variables.icon,
        right: '16px',
        transform: 'none'
    },
    '& .MuiPopover-paper': {
        marginTop: '8px'
    }
}));

const StyledMenuItem = styled(MenuItem)(({ isLastItem, theme, isEllipsisActive, isDesktop }) => ({
    padding: isDesktop ? '16px' : '12px 16px',
    borderBottom: isLastItem ? 'none' : `2px solid ${theme.palette.variables.borderDefault}`,
    minHeight: 'unset',
    '& .MuiTypography-root': {
        fontFamily: FontFamily.PLUSJAKARTASANS,
        fontSize: isDesktop ? '18px' : '14px',
        fontWeight: isDesktop ? 600 : 500,
        fontStyle: 'normal',
        lineHeight: isDesktop ? '22px' : '17px',
        color: theme.palette.variables.prominent,
        textOverflow: isEllipsisActive ? 'ellipsis' : 'unset',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        WebkitLineClamp: 2,
        whiteSpace: 'normal'
    },
    '&.Mui-selected': {
        backgroundColor: theme.palette.variables.borderDefault,
        '&.Mui-focusVisible': {
            backgroundColor: theme.palette.variables.borderDefault
        }
    },
    '&.Mui-selected:hover': {
        backgroundColor: theme.palette.variables.borderDefault
    },
    '& .MuiTouchRipple-root': {
        color: theme.palette.variables.cardPressed
    },
    ':hover': {
        backgroundColor: theme.palette.variables.cardHover
    }
}))

const DropdownSelect = (props) => {

    const {
        value,
        handleChangeSelect,
        options
    } = props

    const { isDesktop } = useContext(AppContext)

    const formRef = useRef(null)
    const textRef = useRef(null)
    const [formWidth, setFormWidth] = useState(0)
    const [viewportSize, setViewportSize] = useState({ height: null, width: null })
    const [isMenulistOpen, setIsMenulistOpen] = useState(false)
    const [isEllipsisActive, setIsEllipsisActive] = useState(false)
    const [formRefBottom, setFormRefBottom] = useState(null)
    const [isMenuBelowMinHeight, setIsMenuBelowMinHeight] = useState(false)
    const [formBottomOffset, setFormBottomOffset] = useState(null)

    const MENU_MARGIN_TOP = 8
    const menuMinHeight = isDesktop ? 224 : 172
    const menuPaddingBottom = isDesktop ? 32 : 16

    const theme = useTheme()

    useEffect(() => {
        if (formRef.current) {
            setFormBottomOffset(formRef.current.getBoundingClientRect().bottom)
        }

        const handleResize = () => {
            setViewportSize({ height: window.innerHeight, width: window.innerWidth })
            if (textRef.current) {
                const lineHeight = isDesktop ? '28px' : '17px'
                const maxHeight = lineHeight * 2
                const actualHeight = textRef.current.clientHeight

                setIsEllipsisActive(actualHeight > maxHeight)
            }
        }
        window.addEventListener("resize", handleResize)
        handleResize()
        return () => window.removeEventListener("resize", handleResize)
    }, [options])

    useEffect(() => {
        setTimeout(() => {
            if (formRef.current && formBottomOffset) {
                const width = formRef.current.offsetWidth
                const bottomOffset = formRef.current.getBoundingClientRect().bottom

                if (window.innerHeight - bottomOffset - menuPaddingBottom - MENU_MARGIN_TOP < menuMinHeight) {
                    setIsMenuBelowMinHeight(true)
                } else {
                    setIsMenuBelowMinHeight(false)
                }
                setFormWidth(width)
                setFormRefBottom(bottomOffset)
            }
        }, 50)
    }, [viewportSize, formBottomOffset])

    const handleOpen = () => {
        setIsMenulistOpen(true)
    }

    const handleClose = () => {
        setIsMenulistOpen(false)
    }

    const getMenuMaxHeight = () => {
        if (isMenuBelowMinHeight) {
            return `${menuMinHeight}px`
        }
        return `calc(100vh - ${formRefBottom}px - ${menuPaddingBottom}px - ${MENU_MARGIN_TOP}px)`
    }

    return (
        <FormControl fullWidth ref={formRef}>
            <StyledSelect
                value={value}
                onChange={handleChangeSelect}
                onOpen={handleOpen}
                onClose={handleClose}
                IconComponent={ChevronDown}
                isMenulistOpen={isMenulistOpen}
                isDesktop={isDesktop}
                theme={theme}
                MenuProps={{
                    PaperProps: {
                        sx: {
                            marginTop: `${MENU_MARGIN_TOP}px`,
                            borderRadius: 0,
                            width: formWidth,
                            minWidth: '0 !important',
                            maxWidth: '100% !important',
                            boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.08)',
                            overflow: 'hidden',
                            '& .MuiList-root': {
                                padding: 0,
                                backgroundColor: theme.palette.variables.cardHover,
                                maxHeight: getMenuMaxHeight(),
                                overflowY: 'auto',
                                overflowX: 'hidden'
                            }
                        }
                    }
                }}
            >
                {options.map((option, optIdx) => (
                    <StyledMenuItem
                        key={option.value}
                        value={option.value}
                        isLastItem={optIdx === options.length - 1}
                        theme={theme}
                        isEllipsisActive={isEllipsisActive}
                        isDesktop={isDesktop}
                    >
                        <Typography ref={textRef}>
                            {option.label}
                        </Typography>
                    </StyledMenuItem>
                ))}
            </StyledSelect>
        </FormControl>
    )
}

export default DropdownSelect