import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../App";
import Button from "../../Components/Button";
import DivFlexCenter from "../../Components/DivFlexCenter";
import { FontFamily } from "../../Constants/FontFamily";

// Hourglass Icon Variants
import { useNavigate, useParams } from "react-router-dom";
import HourglassImageBlue from "../../assets/images/hourglass-blue.png";
import HourglassImageDark from "../../assets/images/hourglass-dark.png";
import HourglassImageGreen from "../../assets/images/hourglass-green.png";
import HourglassImageOrange from "../../assets/images/hourglass-orange.png";
import HourglassImageWhite from "../../assets/images/hourglass-white.png";
import HourglassImageYellow from "../../assets/images/hourglass-yellow.png";

const LinkExpiredStatus = () => {
    const { isDesktop, isMobile } = useContext(AppContext);
    const theme = useTheme();
    const { t } = useTranslation();

    const param = useParams();
    const navigate = useNavigate();

    const handleRedirectToLoginPage = () => {
        let link = ""

        if (param.link) {
            link = param.link
        }

        navigate(`/login?link=${link}`)
    }

    let hourglassImage;

    switch (theme.name) {
        case 'darkTheme':
            hourglassImage = HourglassImageDark;
            break;
        case 'lightTheme':
            hourglassImage = HourglassImageWhite;
            break;
        case 'yellowTheme':
            hourglassImage = HourglassImageYellow;
            break;
        case 'blueTheme':
            hourglassImage = HourglassImageBlue;
            break;
        case 'orangeTheme':
            hourglassImage = HourglassImageOrange;
            break;
        case 'greenTheme':
            hourglassImage = HourglassImageGreen;
            break;
        default:
            hourglassImage = HourglassImageWhite;
    }

    return (
        <DivFlexCenter
            sx={{
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: theme.palette.background.general,
                margin: 0
            }}
        >
            <Box>
                <DivFlexCenter>
                    <img
                        src={hourglassImage}
                        alt="Hourglass icon"
                        style={{
                            width: isMobile ? '160px' : '248.03px',
                            marginBottom: '24px',
                            fill: "red"
                        }}
                    />
                </DivFlexCenter>

                <Typography
                    fontSize={isMobile ? 16 : 28}
                    color={theme.palette.variables.mutedDefault}
                    sx={{
                        fontFamily: FontFamily.PLUSJAKARTASANS,
                        fontWeight: 600,
                        textAlign: 'center',
                        marginBottom: '16px'
                    }}
                >
                    {t("The Link has Expired")}
                </Typography>

                <Typography
                    fontSize={isMobile ? 14 : 16}
                    color={theme.palette.variables.mutedDefault}
                    sx={{
                        fontFamily: FontFamily.PLUSJAKARTASANS,
                        fontWeight: 400,
                        textAlign: 'center',
                        marginBottom: '32px'
                    }}
                >
                    {t("Please sign in again to the Customer Portal by clicking the Login button below.")}
                </Typography>

                <DivFlexCenter>
                    <Button
                        onClick={handleRedirectToLoginPage}
                        style={{
                            width: isMobile ? 'auto' : '226px',
                            height: isMobile ? '48px' : '56px',
                            padding: isMobile ? '12px 40px' : '12px 24px',
                            backgroundColor: theme.palette.background.buttonBgDefault,
                            color: theme.palette.variables.buttonFg,
                            borderRadius: '4px',
                            fontFamily: FontFamily.PLUSJAKARTASANS,
                            fontSize: isMobile ? '14px' : '16px',
                            fontWeight: 600
                        }}
                    >
                        {t("Sign In")}
                    </Button>
                </DivFlexCenter>

            </Box>
        </DivFlexCenter>
    );
};

export default LinkExpiredStatus;