import React, { useContext, useEffect, useState } from "react";

// import i18n
import { useTranslation } from "react-i18next";

// import context
import { AppContext } from "../../App";
import { SignInContext } from "./SignIn";

// import constant
import { BannerVariant } from "../../Constants/BannerVariant";
import { FontFamily } from "../../Constants/FontFamily";
import { SignUpPages } from "../../Constants/SignUpPages";

// import theme
import { useTheme } from "@mui/material/styles";

// import icons
import AlertCircle from "../../assets/icons/AlertCircle";

// import components
import { Box, Skeleton, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { CORE_ACTION_GET_OUTLET_EMAILS_FROM_ETA_LINK } from "../../Actions/CoreActions";
import Banner from "../../Components/Banner/Banner";
import Button from "../../Components/Button";
import DropdownSelect from "../../Components/DropdownSelect";
import ErrorMessage from "../../Components/ErrorMessage";
import TextFieldStyled from "../../Components/TextField/TextFieldStyle";
import TopNavbar from "../../Components/TopNavbar/TopNavbar";

const Header = (props) => {
    const { theme } = props
    const { logo, isLogin, isLogoImage } = useContext(AppContext)

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                width: '100%',
                height: 'fit-content',
                pl: '115px',
                pr: '40px',
                pt: '48px'
            }}
        >
            {isLogoImage ? (
                <img
                    src={logo}
                    alt="company logo"
                    style={{
                        height: '63px'
                    }}
                />
            ) : (
                <Typography
                    color={theme.palette.text.topNavbarText}
                    sx={{
                        fontFamily: FontFamily.PLUSJAKARTASANSBOLD,
                        fontSize: '48px',
                        lineHeight: '48px',
                        textTransform: 'uppercase',
                        maxWidth: 500,
                    }}
                >
                    {logo}
                </Typography>
            )}

            <TopNavbar
                shouldShowSettings={false}
                theme={theme}
                isLogin={isLogin}
                isSignUp
            />
        </Box>
    )
}

const EmailConfirmationFlow = props => {
    const {
        handleUseAnotherEmail,
        resendTimer,
        expiredTimer,
        resendActivation,
        resendActivationEmailLoading
    } = useContext(SignInContext)

    const { theme } = props

    const { t } = useTranslation()

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography
                    sx={{
                        fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD,
                        fontSize: 56,
                        lineHeight: '73px',
                        color: theme.palette.text.primary
                    }}
                >
                    {t('oneMoreStepAndYouAreAllSet')}
                </Typography>
                {expiredTimer <= 0 &&
                    <Box sx={{ marginTop: '32px' }}>
                        <Banner
                            variant={BannerVariant.DANGER}
                            fontSize={18}
                            width={'100%'}
                            icon={<AlertCircle />}
                        >
                            {t("theActivationLinkIsExpired")}
                        </Banner>
                    </Box>
                }
                <Typography
                    sx={{
                        fontFamily: FontFamily.PLUSJAKARTASANS,
                        fontSize: 24,
                        color: theme.palette.text.primary,
                        marginTop: '32px'
                    }}
                >
                    {t('2_1-2-3_bridging_subtitle')}
                    <br /><br />
                    {t('3_1-2-3_bridging_subtitle')}
                </Typography>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', marginTop: '80px' }}>
                <Box sx={{ display: 'flex' }}>
                    <Typography
                        sx={{
                            fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD,
                            fontSize: 21,
                            color: (resendTimer > 0 || resendActivationEmailLoading) ? theme.palette.text.disabled : theme.palette.text.primary,
                            textDecorationLine: 'underline',
                            cursor: (resendTimer <= 0 && !resendActivationEmailLoading) && 'pointer'
                        }}
                        onClick={resendActivationEmailLoading ? undefined : resendActivation}
                    >
                        {`${t("resendActivationEmail")} ${resendTimer > 0 ? `(${resendTimer})` : ``}`}
                    </Typography>
                </Box>

                <Box sx={{ display: 'flex' }}>
                    <Typography
                        sx={{
                            fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD,
                            fontSize: 21,
                            color: theme.palette.text.primary,
                            textDecorationLine: 'underline',
                            cursor: 'pointer'
                        }}
                        onClick={handleUseAnotherEmail}
                    >
                        {t("useAnotherEmailAddress")}
                    </Typography>
                </Box>
            </Box>
        </Box >
    )
}

const InputEmailManualFlow = (props) => {
    const {
        email,
        setEmail,
        isEmailEmpty,
        isEmailInvalid,
        isEmailExist,
        handleChangeEmail,
        handleSubmitEmail,
        signInLoading
    } = useContext(SignInContext)

    const {
        setShouldShowSettings,
        setIsLoading,
        isLoading
    } = useContext(AppContext)

    const { t } = useTranslation()
    const { theme } = props

    const [emails, setEmails] = useState([])

    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {
        setShouldShowSettings(false)
        setIsLoading(true)
        let link = searchParams.get("link")

        if (!link) {
            link = ""
        }

        CORE_ACTION_GET_OUTLET_EMAILS_FROM_ETA_LINK(
            link,
            res => {
                if (res.data) {
                    const emailOptions = []
                    res.data.forEach(email => {
                        emailOptions.push({
                            value: email.email,
                            label: email.email
                        })
                    })
                    setEmail(emailOptions[0].value)
                    setEmails(emailOptions)
                }
                setIsLoading(false)
            },
            err => {
                setIsLoading(false)
                console.log(err)
            })

    }, [])

    const isFieldInvalid = isEmailEmpty || isEmailInvalid || isEmailExist

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography
                    sx={{
                        fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD,
                        fontSize: 56,
                        lineHeight: '80px',
                        color: theme.palette.text.primary
                    }}
                >
                    {t("1_1-2-6_header_title")}
                </Typography>
                <Typography
                    sx={{
                        fontFamily: FontFamily.PLUSJAKARTASANS,
                        fontSize: 20,
                        lineHeight: '28px',
                        color: theme.palette.text.primary,
                        marginTop: '32px'
                    }}
                >
                    {t("2_1-2-6_header_subtitle")}
                </Typography>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography
                    sx={{
                        fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD,
                        fontSize: 20,
                        color: theme.palette.text.primary,
                        marginTop: '64px',
                        marginBottom: '12px'
                    }}
                >
                    {`${t("email")} *`}
                </Typography>
                {isLoading ? (
                    <Skeleton variant="rounded" width={'100%'} height={76} />
                ) : (
                    <>
                        {emails.length > 0 ? (
                            <DropdownSelect
                                options={emails}
                                value={email}
                                handleChangeSelect={handleChangeEmail}
                            />
                        ) : (
                            <TextFieldStyled
                                placeholder="example@mail.com"
                                style={{ width: '100%' }}
                                padding="24px 16px"
                                fontSize="20px"
                                value={email}
                                onChange={handleChangeEmail}
                                warning={isFieldInvalid}
                            />
                        )}
                        {isFieldInvalid &&
                            <ErrorMessage
                                isEmailEmpty={isEmailEmpty}
                                isEmailInvalid={isEmailInvalid}
                                isEmailExist={isEmailExist}
                                isDesktop={true}
                                isMobile={false}
                            />
                        }
                    </>
                )}

            </Box>

            <Button
                style={{
                    width: 'fit-content',
                    padding: '16px 62.5px',
                    height: 'unset',
                    boxShadow: '0px 16px 24px 0px rgba(0, 0, 0, 0.14)',
                    marginTop: '80px'
                }}
                isLoading={signInLoading}
                onClick={handleSubmitEmail}
            >
                <Typography
                    sx={{ fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD, fontSize: 20 }}
                >
                    {t("submit")}
                </Typography>
            </Button>
        </Box >
    )
}

const SignInDesktop = (props) => {
    const { currentPage } = useContext(SignInContext)
    const { img } = props

    const theme = useTheme()

    const selectPageContent = () => {
        switch (currentPage) {
            case SignUpPages.INPUT_REG_EMAIL:
                return <InputEmailManualFlow theme={theme} />
            case SignUpPages.EMAIL_CONFIRMATION:
                return <EmailConfirmationFlow theme={theme} />
            default:
                return <InputEmailManualFlow theme={theme} />
        }
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box sx={{ width: 'calc(100vw - 840px)', height: '100vh' }}>
                <img src={img} alt="company logo" style={{ objectFit: 'cover', width: '100%', height: '100vh' }} />
            </Box>
            <Box sx={{ height: '100vh', width: '840px', overflow: 'auto' }}>
                <Header theme={theme} />
                <Box
                    sx={{
                        display: 'flex',
                        width: '100%',
                        minHeight: 'calc(100% - 122px)',
                        padding: '104px 131px 80px 115px',
                    }}
                >
                    {selectPageContent()}
                </Box>
            </Box>
        </Box>
    )
}

export default SignInDesktop