// import logo from "../src/assets/Images/dummy-promo-lemonade.jpg";
import jetztRabatt from "../src/assets/images/Jetzt_Rabatt_Auf_Purwasser_Sichern.png";
import neuesBier from "../src/assets/images/Neues_Bier_ab_Marz.png";
import wirLaden from "../src/assets/images/Wir_Laden_Ihre_Mitarbeiten.png";

import moment from "moment";

export const promoDummyData = [
  {
    id: 1,
    images: [
      { preview: neuesBier },
      { preview: jetztRabatt },
      { preview: wirLaden }
    ],
    headline: "Nimm einen Schluck! Das neue Bier kommt im März",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    startDate: "2023-11-13",
    endDate: "2023-12-13",
    period: "14 Nov 23 - 14 Dec 23",
  },
  {
    id: 2,
    images: [
      { preview: jetztRabatt }
    ],
    headline: "Genießen Sie eine Ermäßigung auf jeden Kauf von Purwasser",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    startDate: "2023-11-13",
    endDate: "2023-12-13",
    period: "14 Nov 23 - 14 Dec 23",
  },
  {
    id: 3,
    images: [
      { preview: wirLaden }
    ],
    headline: "Besuchen Sie unseren Gastro-Workshop am 23. Mai 2023",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    startDate: "2023-11-13",
    endDate: "2023-12-13",
    period: "14 Nov 23 - 14 Dec 23",
  },
];



export const deliveryDummyData = [
  {
    id: 51721,
    tracesId: "eec5266e-9969-41aa-a12a-41778a731044",
    orders: [
      {
        orderPositions: [
          {
            tenant: "commsult AG",
            tourNumber: "1101600",
            articleNumber: "0010242",
            text: "Frische Brise ohne Kohlensäure 12/0,75",
            amount: 0,
            unit: "KA",
            newAmount: null,
            isNew: null,
            isEmptyReturn: null,
            itemType: null,
            returnItemType: null,
            textItemType: null,
            inventoryItemType: null,
            emptyItemType: null,
            freeItem: null,
            visibleOnClient: null
          },
          {
            tenant: "commsult AG",
            tourNumber: "1101600",
            articleNumber: "0008210",
            text: "König Pilsener 11er 11/0,50",
            amount: 2,
            unit: "KA",
            newAmount: null,
            isNew: null,
            isEmptyReturn: null,
            itemType: null,
            returnItemType: null,
            textItemType: null,
            inventoryItemType: null,
            emptyItemType: null,
            freeItem: null,
            visibleOnClient: null
          },
          {
            tenant: "commsult AG",
            tourNumber: "1101600",
            articleNumber: "0008245",
            text: "Grevensteiner Original 30-Ltr",
            amount: 0,
            unit: "FA",
            newAmount: 5,
            isNew: null,
            isEmptyReturn: null,
            itemType: null,
            returnItemType: null,
            textItemType: null,
            inventoryItemType: null,
            emptyItemType: null,
            freeItem: null,
            visibleOnClient: null,
            warning: 1
          },
          {
            tenant: "commsult AG",
            tourNumber: "1101600",
            articleNumber: "0015433",
            text: "St. Michaelis Brunnen Gourmet 12/0,75",
            amount: 5,
            unit: "KA",
            newAmount: null,
            isNew: null,
            isEmptyReturn: null,
            itemType: null,
            returnItemType: null,
            textItemType: null,
            inventoryItemType: null,
            emptyItemType: null,
            freeItem: null,
            visibleOnClient: null,
          },
          {
            tenant: "commsult AG",
            tourNumber: "1101600",
            articleNumber: "0015438",
            text: "St. Michaelis Naturell Gourmet 12/0,75",
            amount: 5,
            unit: "KA",
            newAmount: null,
            isNew: null,
            isEmptyReturn: null,
            itemType: null,
            returnItemType: null,
            textItemType: null,
            inventoryItemType: null,
            emptyItemType: null,
            freeItem: null,
            visibleOnClient: null,
          },
          {
            tenant: "commsult AG",
            tourNumber: "1101600",
            articleNumber: "0046795",
            text: "Port. Weißherbst halbtr. Schloß Friedelsheim 6/1,00",
            amount: 5,
            unit: "KA",
            newAmount: 7,
            isNew: null,
            isEmptyReturn: null,
            itemType: null,
            returnItemType: null,
            textItemType: null,
            inventoryItemType: null,
            emptyItemType: null,
            freeItem: null,
            visibleOnClient: null,
            warning: 1
          },
          {
            tenant: "commsult AG",
            tourNumber: "1101600",
            articleNumber: "0015389",
            text: "Franziskaner Alkoholfrei 20/0,50",
            amount: 2,
            unit: "KA",
            newAmount: 5,
            isNew: null,
            isEmptyReturn: null,
            itemType: null,
            returnItemType: null,
            textItemType: null,
            inventoryItemType: null,
            emptyItemType: null,
            freeItem: null,
            visibleOnClient: null,
            warning: 1
          },
          {
            tenant: "commsult AG",
            tourNumber: "1101600",
            articleNumber: "0074005",
            text: "CocktailPlant Pina Colada 24/0,20",
            amount: 1,
            unit: "KA",
            newAmount: null,
            isNew: null,
            isEmptyReturn: null,
            itemType: null,
            returnItemType: null,
            textItemType: null,
            inventoryItemType: null,
            emptyItemType: null,
            freeItem: null,
            visibleOnClient: null,
          },
          {
            tenant: "commsult AG",
            tourNumber: "1101600",
            articleNumber: "0074100",
            text: "CocktailPlant Strawberry Colada - alkoholfrei - 24/0,20",
            amount: 1,
            unit: "KA",
            newAmount: null,
            isNew: null,
            isEmptyReturn: null,
            itemType: null,
            returnItemType: null,
            textItemType: null,
            inventoryItemType: null,
            emptyItemType: null,
            freeItem: null,
            visibleOnClient: null,
          },
          {
            tenant: "commsult AG",
            tourNumber: "1101600",
            articleNumber: "0030997",
            text: "Red Stag by Jim Beam 6/0,70",
            amount: 10,
            unit: "FL",
            newAmount: null,
            isNew: null,
            isEmptyReturn: null,
            itemType: null,
            returnItemType: null,
            textItemType: null,
            inventoryItemType: null,
            emptyItemType: null,
            freeItem: null,
            visibleOnClient: null,
          }
        ],
        tenant: "commsult AG",
        tourNumber: "172849",
        stopNumber: "232562",
        orderNumber: "33317639",
        address: {
          street: "Zwischenbrücken 14 ",
          city: "Wildeshausen",
          zipcode: "27793",
          country: "DEU"
        },
        customerNumber: "232562",
        customerNumberExternal: null,
        customerName: "Dirk Langer",
        contractNumber: null,
        customerLoadingLocation: null
      },
      {
        orderPositions: [
          {
            tenant: "commsult AG",
            tourNumber: "1101600",
            articleNumber: "0015229",
            text: "Fanta Orange 24/0,20",
            amount: 10,
            unit: "KA",
            newAmount: 20,
            isNew: null,
            isEmptyReturn: null,
            itemType: null,
            returnItemType: null,
            textItemType: null,
            inventoryItemType: null,
            emptyItemType: null,
            freeItem: null,
            visibleOnClient: null,
            warning: 1
          },
          {
            tenant: "commsult AG",
            tourNumber: "1101600",
            articleNumber: "0015245",
            text: "Sprite 24/0,20",
            amount: 10,
            unit: "KA",
            newAmount: 20,
            isNew: null,
            isEmptyReturn: null,
            itemType: null,
            returnItemType: null,
            textItemType: null,
            inventoryItemType: null,
            emptyItemType: null,
            freeItem: null,
            visibleOnClient: null,
            warning: 1
          },
          {
            tenant: "commsult AG",
            tourNumber: "1101600",
            articleNumber: "0016078",
            text: "Steinmeier Orangensaft 12/0,20",
            amount: 20,
            unit: "KA",
            newAmount: null,
            isNew: null,
            isEmptyReturn: null,
            itemType: null,
            returnItemType: null,
            textItemType: null,
            inventoryItemType: null,
            emptyItemType: null,
            freeItem: null,
            visibleOnClient: null
          },
          {
            tenant: "commsult AG",
            tourNumber: "1101600",
            articleNumber: "0091526",
            text: "Seeberger Kaofix Kakohalt. Getränkepulver 10/1 kg",
            amount: 1,
            unit: "KT",
            newAmount: null,
            isNew: null,
            isEmptyReturn: null,
            itemType: null,
            returnItemType: null,
            textItemType: null,
            inventoryItemType: null,
            emptyItemType: null,
            freeItem: null,
            visibleOnClient: null
          },
          {
            tenant: "commsult AG",
            tourNumber: "1101600",
            articleNumber: "0035934",
            text: "Johannsen Jamaika Rum 1878 - Verschnitt 6/0,70",
            amount: 5,
            unit: "FL",
            newAmount: null,
            isNew: null,
            isEmptyReturn: null,
            itemType: null,
            returnItemType: null,
            textItemType: null,
            inventoryItemType: null,
            emptyItemType: null,
            freeItem: null,
            visibleOnClient: null
          }
        ],
        tenant: "commsult AG",
        tourNumber: "1101600",
        stopNumber: "232562",
        orderNumber: "33320302",
        address: {
          street: "Zwischenbrücken 14 ",
          city: "Wildeshausen",
          zipcode: "27793",
          country: "DEU"
        },
        customerNumber: "232562",
        customerNumberExternal: null,
        customerName: "Gilde Stube",
        contractNumber: null,
        customerLoadingLocation: null
      }
    ],
    tenant: "commsult AG",
    customerName: "Gilde Stube",
    email: null,
    tourNumberGL: "444521",
    tripNumber: "1",
    stopNumber: "232562",
    tourDate: moment(),
    stopIndex: 0,
    stopStatus: "OPEN",
    duration: 28,
    address: {
      street: "Zwischenbrücken 14",
      city: "Wildeshausen",
      zipcode: "27793",
      country: "DEU"
    },
    coordinate: {
      latitude: 52.898,
      longitude: 8.442,
      time: null
    },
    tourStopNotifications: {
      eta: moment().format("YYYY-MM-DD") + "T08:30:38.628954Z",
      notification0: {
        twStart: "2022-03-11T07:50:38.628954Z",
        twEnd: "2022-03-11T09:50:38.628954Z",
        twStatus: 1,
        twNotificationDate: null,
        twFirstStart: "2022-03-11T10:45:00Z",
        twFirstEnd: "2022-03-11T12:45:00Z"
      },
      notification1: {
        twStart: "2022-03-11T08:10:38.628954Z",
        twEnd: "2022-03-11T09:10:38.628954Z",
        twStatus: null,
        twNotificationDate: null,
        twFirstStart: null,
        twFirstEnd: null
      },
      notification2: null,
      notificationLive: {
        twStart: "2022-03-11T08:10:38.628954Z",
        twEnd: "2022-03-11T09:15:38.628954Z",
        twStatus: 1,
        twNotificationDate: "2022-03-11T08:22:38.629747Z",
        twFirstStart: "2022-03-11T10:45:00Z",
        twFirstEnd: "2022-03-11T12:45:00Z"
      },
      actual: {
        twStart: moment().format("YYYY-MM-DD") + "T08:10:38.628954Z",
        twEnd: moment().format("YYYY-MM-DD") + "T09:15:38.628954Z",
        // twStart: "2022-03-11T08:10:38.628954Z",
        // twEnd: "2022-03-11T09:15:38.628954Z",
        twStatus: 1,
        twNotificationDate: "2022-03-11T08:22:38.629747Z",
        twFirstStart: "2022-03-11T10:45:00Z",
        twFirstEnd: "2022-03-11T12:45:00Z"
      }
    },
    durationFromLastStop: 8,
    stopStart: "2022-03-11T08:22:43Z",
    stopEnd: "2022-03-11T09:05:20.814Z",
    stopApproach: "2022-03-11T08:22:37.64Z",
    twStart: null,
    twEnd: null
  },
  {
    id: 51720,
    tracesId: "eec5266e-9969-41aa-a12a-41778a731045",
    orders: [
      {
        orderPositions: [
          {
            tenant: "commsult AG",
            tourNumber: "1101600",
            articleNumber: "0015438",
            text: "St. Michaelis Naturell Gourmet 12/0,75",
            amount: 5,
            unit: "KA",
            newAmount: null,
            isNew: null,
            isEmptyReturn: null,
            itemType: null,
            returnItemType: null,
            textItemType: null,
            inventoryItemType: null,
            emptyItemType: null,
            freeItem: null,
            visibleOnClient: null,
          },
          {
            tenant: "commsult AG",
            tourNumber: "1101600",
            articleNumber: "0046795",
            text: "Port. Weißherbst halbtr. Schloß Friedelsheim 6/1,00",
            amount: 5,
            unit: "KA",
            newAmount: 7,
            isNew: null,
            isEmptyReturn: null,
            itemType: null,
            returnItemType: null,
            textItemType: null,
            inventoryItemType: null,
            emptyItemType: null,
            freeItem: null,
            visibleOnClient: null,
            warning: 1
          },
          {
            tenant: "commsult AG",
            tourNumber: "1101600",
            articleNumber: "0015389",
            text: "Franziskaner Alkoholfrei 20/0,50",
            amount: 2,
            unit: "KA",
            newAmount: 5,
            isNew: null,
            isEmptyReturn: null,
            itemType: null,
            returnItemType: null,
            textItemType: null,
            inventoryItemType: null,
            emptyItemType: null,
            freeItem: null,
            visibleOnClient: null,
            warning: 1
          },
          {
            tenant: "commsult AG",
            tourNumber: "1101600",
            articleNumber: "0074005",
            text: "CocktailPlant Pina Colada 24/0,20",
            amount: 1,
            unit: "KA",
            newAmount: null,
            isNew: null,
            isEmptyReturn: null,
            itemType: null,
            returnItemType: null,
            textItemType: null,
            inventoryItemType: null,
            emptyItemType: null,
            freeItem: null,
            visibleOnClient: null,
          },
          {
            tenant: "commsult AG",
            tourNumber: "1101600",
            articleNumber: "0074100",
            text: "CocktailPlant Strawberry Colada - alkoholfrei - 24/0,20",
            amount: 1,
            unit: "KA",
            newAmount: null,
            isNew: null,
            isEmptyReturn: null,
            itemType: null,
            returnItemType: null,
            textItemType: null,
            inventoryItemType: null,
            emptyItemType: null,
            freeItem: null,
            visibleOnClient: null,
          },
          {
            tenant: "commsult AG",
            tourNumber: "1101600",
            articleNumber: "0030997",
            text: "Red Stag by Jim Beam 6/0,70",
            amount: 10,
            unit: "FL",
            newAmount: null,
            isNew: null,
            isEmptyReturn: null,
            itemType: null,
            returnItemType: null,
            textItemType: null,
            inventoryItemType: null,
            emptyItemType: null,
            freeItem: null,
            visibleOnClient: null,
          }
        ],
        tenant: "commsult AG",
        tourNumber: "172849",
        stopNumber: "232562",
        orderNumber: "33317639",
        address: {
          street: "Zwischenbrücken 14 ",
          city: "Wildeshausen",
          zipcode: "27793",
          country: "DEU"
        },
        customerNumber: "232562",
        customerNumberExternal: null,
        customerName: "Dirk Langer",
        contractNumber: null,
        customerLoadingLocation: null
      },
      {
        orderPositions: [
          {
            tenant: "commsult AG",
            tourNumber: "1101600",
            articleNumber: "0015229",
            text: "Fanta Orange 24/0,20",
            amount: 10,
            unit: "KA",
            newAmount: 20,
            isNew: null,
            isEmptyReturn: null,
            itemType: null,
            returnItemType: null,
            textItemType: null,
            inventoryItemType: null,
            emptyItemType: null,
            freeItem: null,
            visibleOnClient: null,
            warning: 1
          },
          {
            tenant: "commsult AG",
            tourNumber: "1101600",
            articleNumber: "0015245",
            text: "Sprite 24/0,20",
            amount: 10,
            unit: "KA",
            newAmount: 20,
            isNew: null,
            isEmptyReturn: null,
            itemType: null,
            returnItemType: null,
            textItemType: null,
            inventoryItemType: null,
            emptyItemType: null,
            freeItem: null,
            visibleOnClient: null,
            warning: 1
          },
          {
            tenant: "commsult AG",
            tourNumber: "1101600",
            articleNumber: "0016078",
            text: "Steinmeier Orangensaft 12/0,20",
            amount: 20,
            unit: "KA",
            newAmount: null,
            isNew: null,
            isEmptyReturn: null,
            itemType: null,
            returnItemType: null,
            textItemType: null,
            inventoryItemType: null,
            emptyItemType: null,
            freeItem: null,
            visibleOnClient: null
          },
          {
            tenant: "commsult AG",
            tourNumber: "1101600",
            articleNumber: "0091526",
            text: "Seeberger Kaofix Kakohalt. Getränkepulver 10/1 kg",
            amount: 1,
            unit: "KT",
            newAmount: null,
            isNew: null,
            isEmptyReturn: null,
            itemType: null,
            returnItemType: null,
            textItemType: null,
            inventoryItemType: null,
            emptyItemType: null,
            freeItem: null,
            visibleOnClient: null
          },
          {
            tenant: "commsult AG",
            tourNumber: "1101600",
            articleNumber: "0035934",
            text: "Johannsen Jamaika Rum 1878 - Verschnitt 6/0,70",
            amount: 5,
            unit: "FL",
            newAmount: null,
            isNew: null,
            isEmptyReturn: null,
            itemType: null,
            returnItemType: null,
            textItemType: null,
            inventoryItemType: null,
            emptyItemType: null,
            freeItem: null,
            visibleOnClient: null
          }
        ],
        tenant: "commsult AG",
        tourNumber: "1101600",
        stopNumber: "232562",
        orderNumber: "33320302",
        address: {
          street: "Zwischenbrücken 14 ",
          city: "Wildeshausen",
          zipcode: "27793",
          country: "DEU"
        },
        customerNumber: "232562",
        customerNumberExternal: null,
        customerName: "Gilde Stube",
        contractNumber: null,
        customerLoadingLocation: null
      }
    ],
    tenant: "commsult AG",
    customerName: "Gilde Stube",
    email: null,
    tourNumberGL: "444520",
    tripNumber: "2",
    stopNumber: "232562",
    tourDate: moment().subtract(7, 'days'),
    stopIndex: 0,
    stopStatus: "FINISHED",
    duration: 28,
    address: {
      street: "Zwischenbrücken 14",
      city: "Wildeshausen",
      zipcode: "27793",
      country: "DEU"
    },
    coordinate: {
      latitude: 52.898,
      longitude: 8.442,
      time: null
    },
    tourStopNotifications: {
      eta: moment().format("YYYY-MM-DD") + "T08:30:38.628954Z",
      notification0: {
        twStart: "2022-03-11T07:50:38.628954Z",
        twEnd: "2022-03-11T09:50:38.628954Z",
        twStatus: 1,
        twNotificationDate: null,
        twFirstStart: "2022-03-11T10:45:00Z",
        twFirstEnd: "2022-03-11T12:45:00Z"
      },
      notification1: {
        twStart: "2022-03-11T08:10:38.628954Z",
        twEnd: "2022-03-11T09:10:38.628954Z",
        twStatus: null,
        twNotificationDate: null,
        twFirstStart: null,
        twFirstEnd: null
      },
      notification2: null,
      notificationLive: {
        twStart: "2022-03-11T08:10:38.628954Z",
        twEnd: "2022-03-11T09:15:38.628954Z",
        twStatus: 1,
        twNotificationDate: "2022-03-11T08:22:38.629747Z",
        twFirstStart: "2022-03-11T10:45:00Z",
        twFirstEnd: "2022-03-11T12:45:00Z"
      },
      actual: {
        twStart: moment().format("YYYY-MM-DD") + "T08:10:38.628954Z",
        twEnd: moment().format("YYYY-MM-DD") + "T09:15:38.628954Z",
        // twStart: "2022-03-11T08:10:38.628954Z",
        // twEnd: "2022-03-11T09:15:38.628954Z",
        twStatus: 1,
        twNotificationDate: "2022-03-11T08:22:38.629747Z",
        twFirstStart: "2022-03-11T10:45:00Z",
        twFirstEnd: "2022-03-11T12:45:00Z"
      }
    },
    durationFromLastStop: 8,
    stopStart: "2022-03-11T08:22:43Z",
    stopEnd: "2022-03-11T09:05:20.814Z",
    stopApproach: "2022-03-11T08:22:37.64Z",
    twStart: null,
    twEnd: null
  },
  // {
  //   id: 51722,
  //   tracesId: "eec5266e-9969-41aa-a12a-41778a731044",
  //   orders: [
  //     {
  //       orderPositions: [
  //         {
  //           tenant: "commsult AG",
  //           tourNumber: "1101600",
  //           articleNumber: "78175",
  //           text: "Pepsi Cola light MW   24x0,33l",
  //           amount: 1,
  //           unit: "KI",
  //           newAmount: null,
  //           isNew: null,
  //           isEmptyReturn: null,
  //           itemType: null,
  //           returnItemType: null,
  //           textItemType: null,
  //           inventoryItemType: null,
  //           emptyItemType: null,
  //           freeItem: null,
  //           visibleOnClient: null
  //         }
  //       ],
  //       tenant: "commsult AG",
  //       tourNumber: "1101600",
  //       stopNumber: "232562",
  //       orderNumber: "33317639",
  //       address: {
  //         street: "Zwischenbrücken 14 ",
  //         city: "Wildeshausen",
  //         zipcode: "27793",
  //         country: "DEU"
  //       },
  //       customerNumber: "232562",
  //       customerNumberExternal: null,
  //       customerName: "Gilde Stube",
  //       contractNumber: null,
  //       customerLoadingLocation: null
  //     },
  //     {
  //       orderPositions: [
  //         {
  //           tenant: "commsult AG",
  //           tourNumber: "1101600",
  //           articleNumber: "261936",
  //           text: "Helbing Kümmel 35%       0,70l",
  //           amount: 6,
  //           unit: "FL",
  //           newAmount: null,
  //           isNew: null,
  //           isEmptyReturn: null,
  //           itemType: null,
  //           returnItemType: null,
  //           textItemType: null,
  //           inventoryItemType: null,
  //           emptyItemType: null,
  //           freeItem: null,
  //           visibleOnClient: null
  //         }
  //       ],
  //       tenant: "commsult AG",
  //       tourNumber: "1101600",
  //       stopNumber: "232562",
  //       orderNumber: "33320302",
  //       address: {
  //         street: "Zwischenbrücken 14 ",
  //         city: "Wildeshausen",
  //         zipcode: "27793",
  //         country: "DEU"
  //       },
  //       customerNumber: "232562",
  //       customerNumberExternal: null,
  //       customerName: "Gilde Stube",
  //       contractNumber: null,
  //       customerLoadingLocation: null
  //     },
  //     {
  //       orderPositions: [
  //         {
  //           tenant: "commsult AG",
  //           tourNumber: "1101600",
  //           articleNumber: "261195",
  //           text: "Doornkaat 38%            0,70l",
  //           amount: 6,
  //           unit: "FL",
  //           newAmount: null,
  //           isNew: null,
  //           isEmptyReturn: null,
  //           itemType: null,
  //           returnItemType: null,
  //           textItemType: null,
  //           inventoryItemType: null,
  //           emptyItemType: null,
  //           freeItem: null,
  //           visibleOnClient: null
  //         }
  //       ],
  //       tenant: "commsult AG",
  //       tourNumber: "1101600",
  //       stopNumber: "232562",
  //       orderNumber: "33320303",
  //       address: {
  //         street: "Zwischenbrücken 14 ",
  //         city: "Wildeshausen",
  //         zipcode: "27793",
  //         country: "DEU"
  //       },
  //       customerNumber: "232562",
  //       customerNumberExternal: null,
  //       customerName: "Gilde Stube",
  //       contractNumber: null,
  //       customerLoadingLocation: null
  //     },
  //     {
  //       orderPositions: [
  //         {
  //           tenant: "commsult AG",
  //           tourNumber: "1101600",
  //           articleNumber: "20238",
  //           text: "Störteb.Schwarzbier  3x6x0,50l",
  //           amount: 1,
  //           unit: "KI",
  //           newAmount: null,
  //           isNew: null,
  //           isEmptyReturn: null,
  //           itemType: null,
  //           returnItemType: null,
  //           textItemType: null,
  //           inventoryItemType: null,
  //           emptyItemType: null,
  //           freeItem: null,
  //           visibleOnClient: null
  //         }
  //       ],
  //       tenant: "commsult AG",
  //       tourNumber: "1101600",
  //       stopNumber: "232562",
  //       orderNumber: "33320841",
  //       address: {
  //         street: "Zwischenbrücken 14 ",
  //         city: "Wildeshausen",
  //         zipcode: "27793",
  //         country: "DEU"
  //       },
  //       customerNumber: "232562",
  //       customerNumberExternal: null,
  //       customerName: "Gilde Stube",
  //       contractNumber: null,
  //       customerLoadingLocation: null
  //     },
  //     {
  //       orderPositions: [
  //         {
  //           tenant: "commsult AG",
  //           tourNumber: "1101600",
  //           articleNumber: "11925",
  //           text: "Haake-Beck Pils            50l",
  //           amount: 4,
  //           unit: "KEG",
  //           newAmount: null,
  //           isNew: null,
  //           isEmptyReturn: null,
  //           itemType: null,
  //           returnItemType: null,
  //           textItemType: null,
  //           inventoryItemType: null,
  //           emptyItemType: null,
  //           freeItem: null,
  //           visibleOnClient: null
  //         },
  //         {
  //           tenant: "commsult AG",
  //           tourNumber: "1101600",
  //           articleNumber: "12753",
  //           text: "Ratsherrn Zwickel naturbel.30l",
  //           amount: 1,
  //           unit: "KEG",
  //           newAmount: null,
  //           isNew: null,
  //           isEmptyReturn: null,
  //           itemType: null,
  //           returnItemType: null,
  //           textItemType: null,
  //           inventoryItemType: null,
  //           emptyItemType: null,
  //           freeItem: null,
  //           visibleOnClient: null
  //         },
  //         {
  //           tenant: "commsult AG",
  //           tourNumber: "1101600",
  //           articleNumber: "20225",
  //           text: "Störteb.Schwarzb.MW   20x0,50l",
  //           amount: 0,
  //           unit: "KI",
  //           newAmount: null,
  //           isNew: null,
  //           isEmptyReturn: null,
  //           itemType: null,
  //           returnItemType: null,
  //           textItemType: null,
  //           inventoryItemType: null,
  //           emptyItemType: null,
  //           freeItem: null,
  //           visibleOnClient: null
  //         },
  //         {
  //           tenant: "commsult AG",
  //           tourNumber: "1101600",
  //           articleNumber: "43953",
  //           text: "Franziskaner Kellerbier 20x0,5",
  //           amount: 3,
  //           unit: "KI",
  //           newAmount: null,
  //           isNew: null,
  //           isEmptyReturn: null,
  //           itemType: null,
  //           returnItemType: null,
  //           textItemType: null,
  //           inventoryItemType: null,
  //           emptyItemType: null,
  //           freeItem: null,
  //           visibleOnClient: null
  //         },
  //         {
  //           tenant: "commsult AG",
  //           tourNumber: "1101600",
  //           articleNumber: "45005",
  //           text: "Franzisk.HWZ alkfr.MW 20x0,50l",
  //           amount: 1,
  //           unit: "KI",
  //           newAmount: null,
  //           isNew: null,
  //           isEmptyReturn: null,
  //           itemType: null,
  //           returnItemType: null,
  //           textItemType: null,
  //           inventoryItemType: null,
  //           emptyItemType: null,
  //           freeItem: null,
  //           visibleOnClient: null
  //         },
  //         {
  //           tenant: "commsult AG",
  //           tourNumber: "1101600",
  //           articleNumber: "45040",
  //           text: "Spaten Münch.hell MW  20x0,50l",
  //           amount: 1,
  //           unit: "KI",
  //           newAmount: null,
  //           isNew: null,
  //           isEmptyReturn: null,
  //           itemType: null,
  //           returnItemType: null,
  //           textItemType: null,
  //           inventoryItemType: null,
  //           emptyItemType: null,
  //           freeItem: null,
  //           visibleOnClient: null
  //         },
  //         {
  //           tenant: "commsult AG",
  //           tourNumber: "1101600",
  //           articleNumber: "262550",
  //           text: "Linie Aquavit 41,5%      0,70l",
  //           amount: 6,
  //           unit: "FL",
  //           newAmount: null,
  //           isNew: null,
  //           isEmptyReturn: null,
  //           itemType: null,
  //           returnItemType: null,
  //           textItemType: null,
  //           inventoryItemType: null,
  //           emptyItemType: null,
  //           freeItem: null,
  //           visibleOnClient: null
  //         },
  //         {
  //           tenant: "commsult AG",
  //           tourNumber: "1101600",
  //           articleNumber: "263532",
  //           text: "Osborne Veterano 30%     0,70l",
  //           amount: 6,
  //           unit: "FL",
  //           newAmount: null,
  //           isNew: null,
  //           isEmptyReturn: null,
  //           itemType: null,
  //           returnItemType: null,
  //           textItemType: null,
  //           inventoryItemType: null,
  //           emptyItemType: null,
  //           freeItem: null,
  //           visibleOnClient: null
  //         },
  //         {
  //           tenant: "commsult AG",
  //           tourNumber: "1101600",
  //           articleNumber: "263740",
  //           text: "Ramazzotti Halbbitter30% 0,70l",
  //           amount: 6,
  //           unit: "FL",
  //           newAmount: null,
  //           isNew: null,
  //           isEmptyReturn: null,
  //           itemType: null,
  //           returnItemType: null,
  //           textItemType: null,
  //           inventoryItemType: null,
  //           emptyItemType: null,
  //           freeItem: null,
  //           visibleOnClient: null
  //         },
  //         {
  //           tenant: "commsult AG",
  //           tourNumber: "1101600",
  //           articleNumber: "264260",
  //           text: "Springer Urvater 28%     0,70l",
  //           amount: 6,
  //           unit: "FL",
  //           newAmount: null,
  //           isNew: null,
  //           isEmptyReturn: null,
  //           itemType: null,
  //           returnItemType: null,
  //           textItemType: null,
  //           inventoryItemType: null,
  //           emptyItemType: null,
  //           freeItem: null,
  //           visibleOnClient: null
  //         },
  //         {
  //           tenant: "commsult AG",
  //           tourNumber: "1101600",
  //           articleNumber: "264696",
  //           text: "Wodka Berov 37,5%        0,70l",
  //           amount: 24,
  //           unit: "FL",
  //           newAmount: null,
  //           isNew: null,
  //           isEmptyReturn: null,
  //           itemType: null,
  //           returnItemType: null,
  //           textItemType: null,
  //           inventoryItemType: null,
  //           emptyItemType: null,
  //           freeItem: null,
  //           visibleOnClient: null
  //         },
  //         {
  //           tenant: "commsult AG",
  //           tourNumber: "1101600",
  //           articleNumber: "274725",
  //           text: "St.Hubertus Johannisb.24x0,02l",
  //           amount: 10,
  //           unit: "KAR",
  //           newAmount: null,
  //           isNew: null,
  //           isEmptyReturn: null,
  //           itemType: null,
  //           returnItemType: null,
  //           textItemType: null,
  //           inventoryItemType: null,
  //           emptyItemType: null,
  //           freeItem: null,
  //           visibleOnClient: null
  //         }
  //       ],
  //       tenant: "commsult AG",
  //       tourNumber: "1101600",
  //       stopNumber: "232562",
  //       orderNumber: "33320937",
  //       address: {
  //         street: "Zwischenbrücken 14 ",
  //         city: "Wildeshausen",
  //         zipcode: "27793",
  //         country: "DEU"
  //       },
  //       customerNumber: "232562",
  //       customerNumberExternal: null,
  //       customerName: "Gilde Stube",
  //       contractNumber: null,
  //       customerLoadingLocation: null
  //     },
  //     {
  //       orderPositions: [
  //         {
  //           tenant: "commsult AG",
  //           tourNumber: "1101600",
  //           articleNumber: "510",
  //           text: "L Kasten ohne Fl.         1,50",
  //           amount: 4,
  //           unit: "KAS",
  //           newAmount: null,
  //           isNew: null,
  //           isEmptyReturn: null,
  //           itemType: null,
  //           returnItemType: null,
  //           textItemType: null,
  //           inventoryItemType: null,
  //           emptyItemType: null,
  //           freeItem: null,
  //           visibleOnClient: null
  //         },
  //         {
  //           tenant: "commsult AG",
  //           tourNumber: "1101600",
  //           articleNumber: "510",
  //           text: "L Kasten ohne Fl.         1,50",
  //           amount: 4,
  //           unit: "KAS",
  //           newAmount: null,
  //           isNew: null,
  //           isEmptyReturn: null,
  //           itemType: null,
  //           returnItemType: null,
  //           textItemType: null,
  //           inventoryItemType: null,
  //           emptyItemType: null,
  //           freeItem: null,
  //           visibleOnClient: null
  //         }
  //       ],
  //       tenant: "commsult AG",
  //       tourNumber: "1101600",
  //       stopNumber: "232562",
  //       orderNumber: "33321339",
  //       address: {
  //         street: "Zwischenbrücken 14 ",
  //         city: "Wildeshausen",
  //         zipcode: "27793",
  //         country: "DEU"
  //       },
  //       customerNumber: "232562",
  //       customerNumberExternal: null,
  //       customerName: "Gilde Stube",
  //       contractNumber: null,
  //       customerLoadingLocation: null
  //     }
  //   ],
  //   tenant: "commsult AG",
  //   email: null,
  //   tourNumber: "1101600",
  //   stopNumber: "232562",
  //   stopIndex: 0,
  //   stopStatus: "FINISHED",
  //   duration: 28,
  //   address: {
  //     street: "Zwischenbrücken 14 ",
  //     city: "Wildeshausen",
  //     zipcode: "27793",
  //     country: "DEU"
  //   },
  //   coordinate: {
  //     latitude: 52.898,
  //     longitude: 8.442,
  //     time: null
  //   },
  //   tourStopNotifications: {
  //     eta: "2022-03-11T08:30:38.628954Z",
  //     notification0: {
  //       twStart: "2022-03-11T07:50:38.628954Z",
  //       twEnd: "2022-03-11T09:50:38.628954Z",
  //       twStatus: 1,
  //       twNotificationDate: null,
  //       twFirstStart: "2022-03-11T10:45:00Z",
  //       twFirstEnd: "2022-03-11T12:45:00Z"
  //     },
  //     notification1: {
  //       twStart: "2022-03-11T08:10:38.628954Z",
  //       twEnd: "2022-03-11T09:10:38.628954Z",
  //       twStatus: null,
  //       twNotificationDate: null,
  //       twFirstStart: null,
  //       twFirstEnd: null
  //     },
  //     notification2: null,
  //     notificationLive: {
  //       twStart: "2022-03-11T08:10:38.628954Z",
  //       twEnd: "2022-03-11T09:15:38.628954Z",
  //       twStatus: 1,
  //       twNotificationDate: "2022-03-11T08:22:38.629747Z",
  //       twFirstStart: "2022-03-11T10:45:00Z",
  //       twFirstEnd: "2022-03-11T12:45:00Z"
  //     },
  //     actual: {
  //       twStart: "2022-03-11T08:10:38.628954Z",
  //       twEnd: "2022-03-11T09:15:38.628954Z",
  //       twStatus: 1,
  //       twNotificationDate: "2022-03-11T08:22:38.629747Z",
  //       twFirstStart: "2022-03-11T10:45:00Z",
  //       twFirstEnd: "2022-03-11T12:45:00Z"
  //     }
  //   },
  //   durationFromLastStop: 8,
  //   stopStart: "2022-03-11T08:22:43Z",
  //   stopEnd: "2022-03-11T09:05:20.814Z",
  //   stopApproach: "2022-03-11T08:22:37.64Z",
  //   twStart: null,
  //   twEnd: null
  // }
];

export const emailDummyList = [
  {
    id: 1,
    email: "Email@example.com",
    roles: "admin",
  },
  {
    id: 2,
    email: "Email2@example.com",
    roles: "standard",
  },
  {
    id: 3,
    email: "Email3@example.com",
    roles: "superAdmin",
  },
  {
    id: 4,
    email: "Email4@example.com",
    roles: "standard",
  },
  {
    id: 5,
    email: "Email5@example.com",
    roles: "admin",
  },
];
