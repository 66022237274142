import React, { useContext } from "react";

// AppContext
import { AppContext } from "../../App";

// MUI components
import { Box, Typography } from "@mui/material";

// Constants
import { FontFamily } from "../../Constants/FontFamily";

// Utils
import { useTranslation } from "react-i18next";

// Icons
import { useNavigate, useParams } from "react-router-dom";
import AddUser from "../../assets/icons/AddUser";
import SettingIcon from "../../assets/icons/SettingIcon";
import { DialogType } from "../../Constants/DialogType";

const TopNavbarMobile = (props) => {
    const { theme, isSignUp, isError } = props

    const param = useParams();
    const navigate = useNavigate();

    const {
        shouldShowSettings,
        settingDisabled,
        isLogin,
        handleOpenNavigationDrawer,
        handleOpenDialog,
        openDialog,
        isLoading,
        isLoadingLogin,
        isLoadingTenant,
        noSuperAdmin,
        outletId,
        loggedinEmail
    } = useContext(AppContext)

    const { i18n } = useTranslation()

    const handleChangeLanguage = (lang) => {
        i18n.changeLanguage(lang)
    }

    const handleOpenWelcomeDialog = () => {
        handleOpenDialog('welcomeGreeting', true)
    }

    const handleRedirectToLoginPage = () => {
        let link = ""

        if (param.link) {
            link = param.link
        }

        navigate(`/login?link=${link}`)
    }

    const lowZIndex = isLoading || isLoadingTenant || isLoadingLogin || openDialog

    const mainColor = isSignUp ? '#F7F5F5' : theme.palette.text.topNavbarText

    return (
        <Box
            sx={{
                width: '100vw',
                display: 'flex',
                padding: `16px 24px`,
                justifyContent: 'space-between',
                alignItems: 'center',
                zIndex: lowZIndex ? 1000 : 1100,
                backgroundColor: isSignUp ? 'none' : theme.palette.background.default
            }}
        >
            <Box sx={{ display: 'flex', height: '30px' }}>
                <Typography
                    fontSize={22}
                    color={mainColor}
                    sx={{
                        fontFamily: FontFamily.PLUSJAKARTASANS,
                        fontStyle: "normal",
                        fontWeight: i18n.language === 'de' ? 600 : 400,
                        cursor: "pointer",
                        lineHeight: "32px",
                        borderBottom: i18n.language === 'de' && `1px solid ${mainColor}`,
                        maxWidth: "fit-content"
                    }}
                    onClick={() => handleChangeLanguage('de')}
                >
                    {`DE`}
                </Typography>

                <Box
                    sx={{
                        paddingLeft: "16px",
                        borderRight: `1px solid ${mainColor}`
                    }}
                />

                <Typography
                    fontSize={22}
                    color={mainColor}
                    sx={{
                        marginLeft: "16px",
                        fontFamily: FontFamily.PLUSJAKARTASANS,
                        fontStyle: "normal",
                        fontWeight: i18n.language === 'en' ? 600 : 400,
                        cursor: "pointer",
                        lineHeight: "32px",
                        borderBottom: i18n.language === 'en' && `1px solid ${mainColor}`,
                        maxWidth: "fit-content"
                    }}
                    onClick={() => handleChangeLanguage('en')}
                >
                    {`EN`}
                </Typography>
            </Box>

            {/* {(noSuperAdmin && !isError) && (
                <UserQuestionMark
                    style={{
                        width: 32,
                        height: 32,
                        color: theme.palette.background.iconColor,
                        cursor: 'pointer'
                    }}
                    onClick={handleOpenWelcomeDialog}
                />
            )} */}
            <div style={{ display: "flex" }}>
                {(shouldShowSettings && outletId && loggedinEmail && isLogin) && (
                    <Box
                        sx={{
                            width: 40,
                            height: 40,
                            mr: 4,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: 20,
                            cursor: !settingDisabled && 'pointer',
                            transition: '0.15s all ease-in-out',
                            "&:hover": {
                                background: theme.palette.background.settingsIconHover,
                                transition: '0.15s all ease-in-out'
                            }
                        }}
                    >
                        <AddUser
                            sx={{
                                fontSize: 50,
                                marginRight: '-24px',
                                marginTop: '20px'
                            }}
                            color={theme.palette.background.settingsIcon}
                            onClick={() => handleOpenDialog(DialogType.INVITEUSER)}
                        />
                    </Box>
                )}
                {(shouldShowSettings) &&
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '4px',
                            borderRadius: '50%',
                            "&:active": {
                                backgroundColor: theme.palette.background.settingsIconPressed,
                                transition: '0.05s all ease-in-out'
                            },
                            "&:hover": {
                                background: theme.palette.background.settingsIconHover,
                                transition: '0.15s all ease-in-out',
                                cursor: 'pointer'
                            }
                        }}
                    >
                        <SettingIcon
                            sx={{
                                fontSize: 32
                            }}
                            color={settingDisabled ? theme.palette.background.settingsIconDisabled : theme.palette.background.settingsIcon}
                            onClick={isLogin ?
                                handleOpenNavigationDrawer :
                                // () => handleOpenDialog(settingDisabled ? 'settingDisabled' : 'login')
                                // () => handleOpenDialog(settingDisabled ? undefined : 'login')
                                handleRedirectToLoginPage
                            }
                        />
                    </Box>
                }
            </div>
        </Box>
    )
}

export default TopNavbarMobile