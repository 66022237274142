import React, { useContext } from "react";

// AppContext
import { AppContext } from "../../App";

// MUI components
import { Box, Typography } from "@mui/material";

// Constants
import { FontFamily } from "../../Constants/FontFamily";

// Utils
import { useTranslation } from "react-i18next";

// Icons
import { useNavigate, useParams } from "react-router-dom";
import AddUser from "../../assets/icons/AddUser";
import SettingIcon from "../../assets/icons/SettingIcon";
import { DialogType } from "../../Constants/DialogType";

const TopNavbarDesktop = (props) => {
    const param = useParams();
    const navigate = useNavigate();

    const { theme } = props

    const {
        shouldShowSettings,
        settingDisabled,
        isLogin,
        handleOpenNavigationDrawer,
        handleOpenDialog,
        noSuperAdmin,
        outletId,
        loggedinEmail
    } = useContext(AppContext)

    const { i18n } = useTranslation()

    const handleChangeLanguage = (lang) => {
        i18n.changeLanguage(lang)
    }

    const handleRedirectToLoginPage = () => {
        let link = ""

        if (param.link) {
            link = param.link
        }

        navigate(`/login?link=${link}`)
    }

    const handleOpenWelcomeDialog = () => {
        handleOpenDialog('welcomeGreeting', true)
    }

    return (
        <Box
            sx={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                // paddingBottom: '16px'
                paddingBottom: '24px',
            }}
        >
            {(shouldShowSettings && outletId && loggedinEmail && isLogin) && (
                <Box
                    sx={{
                        width: 40,
                        height: 40,
                        mr: 4,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: 20,
                        cursor: !settingDisabled && 'pointer',
                        transition: '0.15s all ease-in-out',
                        "&:hover": {
                            background: theme.palette.background.settingsIconHover,
                            transition: '0.15s all ease-in-out'
                        }
                    }}
                >
                    <AddUser
                        sx={{
                            fontSize: 50,
                            marginRight: '-24px',
                            marginTop: '20px'
                        }}
                        color={theme.palette.background.settingsIcon}
                        onClick={() => handleOpenDialog(DialogType.INVITEUSER)}
                    />
                </Box>
            )}
            {(shouldShowSettings) && (
                <Box
                    sx={{
                        width: 40,
                        height: 40,
                        mr: 4,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: 20,
                        cursor: !settingDisabled && 'pointer',
                        transition: '0.15s all ease-in-out',
                        "&:hover": {
                            background: theme.palette.background.settingsIconHover,
                            transition: '0.15s all ease-in-out'
                        }
                    }}
                >
                    <SettingIcon
                        sx={{
                            fontSize: 35,
                            marginRight: '-0.5px'
                        }}
                        color={settingDisabled ? theme.palette.background.settingsIconDisabled : theme.palette.background.settingsIcon}
                        onClick={isLogin ?
                            handleOpenNavigationDrawer :
                            !settingDisabled ? () => handleRedirectToLoginPage() : undefined}
                    />
                </Box>
            )}

            {/* {noSuperAdmin && (
                <UserQuestionMark
                    style={{
                        marginRight: '28px',
                        width: 32,
                        height: 32,
                        color: theme.palette.background.iconColor,
                        cursor: 'pointer'
                    }}
                    onClick={handleOpenWelcomeDialog}
                />
            )} */}

            <Box sx={{ display: 'flex', height: '30px' }}>
                <Typography
                    fontSize={22}
                    color={theme.palette.text.topNavbarText}
                    sx={{
                        fontFamily: i18n.language === 'de' ? FontFamily.PLUSJAKARTASANSSEMIBOLD : FontFamily.PLUSJAKARTASANS,
                        fontStyle: "normal",
                        cursor: 'pointer',
                        lineHeight: "32px",
                        borderBottom: i18n.language === 'de' && `1px solid ${theme.palette.text.topNavbarText}`
                    }}
                    onClick={() => handleChangeLanguage('de')}
                >
                    {`DE`}
                </Typography>

                <Box
                    sx={{
                        paddingLeft: "16px",
                        borderRight: `1px solid ${theme.palette.text.verticalSeparator}`
                    }}
                />

                <Typography
                    fontSize={22}
                    color={theme.palette.text.topNavbarText}
                    sx={{
                        marginLeft: "16px",
                        fontFamily: i18n.language === 'en' ? FontFamily.PLUSJAKARTASANSSEMIBOLD : FontFamily.PLUSJAKARTASANS,
                        fontStyle: "normal",
                        cursor: 'pointer',
                        lineHeight: "32px",
                        borderBottom: i18n.language === 'en' && `1px solid ${theme.palette.text.topNavbarText}`
                    }}
                    onClick={() => handleChangeLanguage('en')}
                >
                    {`EN`}
                </Typography>
            </Box>
        </Box>
    )
}

export default TopNavbarDesktop